
/***************/
/* MEYER RESET */
/***************/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
sub, sup {
	/* Specified in % so that the sup/sup is the
     right size relative to the surrounding text */
	font-size: 75%;

	/* Zero out the line-height so that it doesn't
     interfere with the positioning that follows */
	line-height: 0;

	/* Where the magic happens: makes all browsers position
     the sup/sup properly, relative to the surrounding text */
	position: relative;

	/* Note that if you're using Eric Meyer's reset.css, this
     is already set and you can remove this rule */
	vertical-align: baseline;
}

sup {
	/* Move the superscripted text up */
	top: -0.5em;
}

sub {
	/* Move the subscripted text down, but only
     half as far down as the superscript moved up */
	bottom: -0.25em;
}
// Apply a natural box layout model to all elements
// from: http://www.paulirish.com/2012/box-sizing-border-box-ftw/
*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}
